<template>
  <div class="padding-top-10 contain_pay">
    <top :bgWhite="true"></top>
    <div class="content">
      <div class="flex align-center padding-bottom-15 solid-bottom">
        <div
          class="padding-lr-20"
          v-for="(item, index) in navList"
          :key="index"
        >
          <span
            class="padding-bottom-19 text-666 point"
            @click="tabOne(item, index)"
            :class="currentIndex == index ? 'active_my' : ''"
            >{{ item.name }}</span
          >
        </div>
      </div>
      <!-- 加载中 -->
      <div class="w100" v-if="!isInit">
        <el-skeleton :rows="12" animated />
      </div>
      <div class="lists" v-else>
        <div class="flex headbox">
          <div style="flex: 3">商品信息</div>
          <div style="flex: 1; text-align: center">数量</div>
          <div style="flex: 1; text-align: center">金额</div>
          <div style="flex: 1; text-align: center">状态</div>
          <div style="flex: 2; text-align: center">操作</div>
        </div>
        <div class="listitem" v-for="(item, index) in lists" :key="index">
          <div class="ordercode">
            <span>{{ item.create_time }}</span>
            <span style="margin-left: 20px">订单编号:{{ item.order_sn }}</span>
          </div>
          <div class="flex item" style="align-items: center">
            <div style="flex: 3; align-items: center" class="flex goods">
              <div>
                <img
                  class="block-86 item-img"
                  :src="item.goods ? item.goods[0].goods_thumb : ''"
                  alt=""
                />
              </div>
              <div>{{ item.goods ? item.goods[0].goods_name : "" }}</div>
            </div>
            <div class="border-r">
              {{ item.goods ? item.goods[0].num : "" }}
            </div>
            <div class="border-r">
              {{ item.goods ? item.goods[0].total_scrabble : "" }}
            </div>
            <div class="border-r">{{ item.status | status }}</div>
            <div class="btn" style="flex: 2; text-align: center">
              <div @click="todetail(item)">查看详情</div>
              <!-- <div
                style="margin-top: 12px"
                v-if="item.status == 1"
                @click="cancel(item)"
              >
                取消订单
              </div> -->
              <div style="margin-top: 12px" v-if="item.status != 1">
                删除订单
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="lists.length == 0">
        <el-empty description="~空空如也~"></el-empty>
      </div>
      <div class="flex align-center margin-top-30">
        <el-pagination
          style="margin: 0 auto"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="15"
          :total="totalSize"
        >
        </el-pagination>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import payCode from "@/components/pay-code/pay-code.vue";
import top from "@/components/top";
import bottom from "@/components/bottom";
import catalogVue from "../fiction/catalog.vue";
export default {
  name: "authen",
  components: {
    bottom,
    top,
    payCode,
  },
  data() {
    return {
      url: "", //
      timer: null,
      activeName: "0",
      page: 1,
      totalSize: 0, //总页数
      type: "",
      lists: [],
      isInit: false,
      currentIndex: 0, //选择的索引
      order_sn: "",
      navList: [
        {
          name: "全部",
          id: 1,
          value: "",
        },
        {
          name: "拼团中",
          id: 2,
          value: "unship",
        },
        {
          name: "拼团成功",
          id: 3,
          value: "clustering",
        },
        {
          name: "排版中",
          id: 4,
          value: "composing",
        },
        {
          name: "印刷中",
          id: 5,
          value: "printing",
        },
        {
          name: "已完成",
          id: 6,
          value: "complete",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  filters: {
    status(key) {
      let obj = {
        0: "未支付",
        1: "待拼成",
        2: "成团",
        3: "印刷中",
        4: "排版中",
        5: "已完成",
        "-1": "已取消",
      };
      return obj[key];
    },
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    tabOne(item, index) {
      if (this.currentIndex == index) return;
      this.currentIndex = index;
      this.type = this.navList[index].value;
      this.lists = [];
      this.page = 1;
      this.loadData();
    },
    loadData() {
      this.ajax(
        "post",
        "/v1/6380d8d3b3b31",
        {
          page: this.page,
          list_rows: 15,
          type: this.type,
        },
        (res) => {
          this.isInit = true;
          this.totalSize = res.data.total;
          this.lists = res.data.list;
        }
      );
    },
    //取消订单
    cancel(item) {
      this.$confirm(`确定取消订单?`, "提示", {
        confirmButtonText: "好的",
        type: "info",
      })
        .then(() => {
          this.ajax(
            "post",
            "/v1/6381679e58f76",
            {
              order_sn: item.order_sn,
            },
            (res) => {
              this.lists = [];
              this.page = 1;
              this.loadData();
            }
          );
        })
        .catch(() => {});
    },
    //去详情
    todetail(item) {
      this.$router.push({
        path: "/order-detail",
        query: {
          id: item.order_sn,
        },
      });
    },
    // 页数改变
    handleSizeChange(e) {
      console.log(e);
      this.page = e;
      this.loadData();
      target_top.scrollIntoView();
    },
    // 跳页
    handleCurrentChange(e) {
      console.log(e);
      this.page = e;
      this.loadData();
      target_top.scrollIntoView();
    },
  },
};
</script>

<style scoped>
.contain_pay {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: auto;
}
.content {
  width: 69%;
  height: auto;
  margin: 80px auto;
}
.lists {
  border: 1px solid #eee;
  padding: 32px 40px;
  margin-top: 32px;
}
.headbox {
  padding: 18px 32px;
  border: 1px solid #eee;
  font-size: 14px;
  color: #333;
  background: #f9f9f9;
}
.listitem {
  margin-bottom: 24px;
}
.ordercode {
  padding: 12px 32px;
  background: #eaf8ff;
  border: 1px solid #9ed1ff;
  font-size: 14px;
  color: #333;
}
.item {
  border: 1px solid #9ed1ff;
}
.item-img {
  padding: 32px;
  display: inline-block;
}
.border-r {
  flex: 1;
  text-align: center;
  height: 148px;
  border-right: 1px solid #9ed1ff;
  line-height: 148px;
}
.goods {
  border-right: 1px solid #9ed1ff;
}
.btn {
  cursor: pointer;
}
/deep/.el-input {
  width: 320px;
  height: 36px;
}
.active_my {
  position: relative;
  color: #333;
  font-weight: bold;
}

.active_my::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  width: 60%;
  margin: auto;
  background-color: #e54f42;
  height: 5px;
  border-radius: 4px;
}
</style>
