<template>
	<div>
		<top :bgWhite='true'></top>
		<div class="contentAll contentAll padding-lr-30  top-50 padding-bottom-50">
			<div class="flex align-center justify-center flex-direction padding-top-sm">
				<div class="title flex align-center">
					<div class="left-line"></div>
					<p class="margin-lr-10">{{data.title || '--'}}</p>
					<div class="right-line"></div>
				</div>
				<div class="margin-top-20">
					<!-- <span class="fs9">作者：{{user.user_nickname}} 最新章节：正文：{{newCap}}</span> -->
					<span class="fs9">作者：{{user.user_nickname}} </span>
				</div>
			</div>
			<!-- 章节列表 -->
			<div class="log_box margin-top-30">
				<div class="log_title flex align-center justify-end ">
					<p class="margin-right-40 fs9 text-blue">已下载</p>
					<div class="margin-right flex align-center point" @click="changeOrder">
						<img src="../../assets/image/icon/asc.png" v-if="order=='asc'" class="block-16 margin-right-5">
						<img src="../../assets/image/icon/desc.png" v-else class="block-16 margin-right-5">
						<span class="fs9">{{order=='asc' ? '正序' :'倒序'}}</span>
					</div>
				</div>
				<div class="log_list ">
					<div class=" padding-lr-20" v-if="lists.length>0">
						<el-row :gutter="24">
							<el-col :span="8" class="list_item" v-for="(item,index) in lists" :key="index">
								<div class="flex align-center point" @click="toAdetail(item)">
									<p class="fs9">{{item.title ||'暂无内容'}}</p>
									<img src="../../assets/image/fee.png" class="block-18 margin-left-5" v-if="is_buy==0 && is_free==0">
								</div>
							</el-col>
						</el-row>
						<!-- 分页 -->
						<div class="flex align-center justify-center">
							<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='15' :hide-on-single-page='true' @current-change="currentChange">
							</el-pagination>
						</div>
						<!-- 分页 end-->
					</div>
					<div class="padding-lr-20 text-center padding-tb-20" v-if="lists.length==0">
						<p>敬请期待作者更新</p>
					</div>
				</div>
			</div>
		</div>
		
		<bottom></bottom>
	</div>
</template>

<script>
	import top from '@/components/top';
	import bottom from '@/components/bottom';
	export default {
		components: {
			top,
			bottom
		},
		data() {
			return {
				aid:'',
				page:1, //页数
				totalSize:0, //分页总数
				order:'asc', //排序方式
				lists:[],
				user:{}, //作者信息
				data:{} ,//详情信息
				newCap:'' ,//最新章节
				is_buy:1, //是否购买
				is_free:0, //是否免费
			}
		},
		mounted() {
			this.aid = this.$route.query.aid;
			this.getLogLists() //获取章节列表
			this.getBookDetail() //获取电子书详情
		},
		methods: {
			// 点击当前页
			currentChange(e){
				this.page = e;
				this.getLogLists()
			},
			changeOrder(){
				this.order = this.order=='asc' ? 'desc' : 'asc';
				this.getLogLists()
			},
			toAdetail(item){
				this.$router.push({
					path:'/adetail',
					query:{
						id:item.id,
						title:item.title,
						newSection:this.newCap,
						document_id:this.aid
					}
				})
			},
			// 获取电子书详情
			getBookDetail() {
				this.ajax('post', '/v1/613afc809f5e3', {
					article_id: this.aid
				}, res => {
					if (res.code == 1) {
						this.user = res.data.user;
						this.data = res.data;
						this.is_buy = res.data.is_buy;
						this.is_free = res.data.is_free
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 获取章节列表
			getLogLists() {
				this.ajax('post', '/v1/61c6b084561cd', {
					page:this.page,
					document_id:this.aid,
					order:this.order
				}, res => {
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data;
						this.getNewCap(res.data.last_page)
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			},
			// 获取最新章节
			getNewCap(last_page){
				this.ajax('post', '/v1/61c6b084561cd', {
					page:last_page,
					document_id:this.aid,
					order:'desc'
				}, res => {
					if (res.code == 1) {
						this.newCap = res.data[0].title
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.title{
		height: 21px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 27px;
		color: #333333;
		opacity: 1;
		z-index: 1;
		.left-line{
			width: 104px;
			height: 2px;
			background: linear-gradient(270deg, #E54F42 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
			border-radius: 1px;
			z-index: 8;
		}
		.right-line{
			width: 104px;
			height: 2px;
			background: linear-gradient(90deg, #E54F42 0%, rgba(255, 255, 255, 0) 100%);
			opacity: 1;
			border-radius: 1px;
		}
	}
	.text-blue{
		color: #3E62B1;
	}
	.log_box{
		.log_title{
			width: 100%;
			height: 48px;
			background: #F6F7F9;
			border: 1px solid #F6F7F9;
			opacity: 1;
		}
		.log_list{
			width: 100%;
			min-height: 40px;
			background: #FFFFFF;
			border: 1px solid #EEEEEE;
			opacity: 1;
			.list_item{
				height: 60px;
				line-height: 60px;
				font-size: 9px;
				border-bottom: 1px dashed  #EEEEEE;
			}
		}
	}

</style>
